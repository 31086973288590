import * as msal from '@azure/msal-browser';
import logger from '../logger';
import { msalAppConfig } from './msalAppConfig';
import { jwtDecode } from 'jwt-decode';

let msalInstance = null;
let msalConfig = null;
let account = null;

export const getMsalConfig = () => {
  if (msalConfig !== null) {
    return msalConfig;
  }
  const config = msalAppConfig.getConfig();
  msalConfig = {
    auth: {
      clientId: config?.clientId,
      authority: `https://login.microsoftonline.com/${config?.tenantId}/`,
      redirectUri: window.location.origin,
    },
  };
  return msalConfig;
};

export const getMsalPublicClientApplication = async (relogin) => {
  if (!relogin && msalInstance !== null) {
    return msalInstance;
  }
  const config = getMsalConfig();

  msalInstance = new msal.PublicClientApplication(config);
  try {
    const response = await msalInstance.handleRedirectPromise();

    if (!response) {
      // Check if the user is already signed in
      const accounts = msalInstance.getAllAccounts();
      if (accounts.length === 0) {
        // If no user is signed in, trigger login
        await msalInstance.loginRedirect({ scopes: ['User.Read'] });
      }
    }
  } catch (error) {
    logger
      .error('Error Acquiring Msal Public Client Application')
      .data({ module: 'msalConfig', error })
      .end();
  }

  return msalInstance;
};

export const getUser = () => {
  if (account !== null) {
    return account;
  }
  const accounts = msalInstance.getAllAccounts();
  account = accounts.length > 0 ? accounts[0] : null;

  return account;
};

export const getToken = async () => {
  const msalInstance = await getMsalPublicClientApplication();
  const account = await getUser();
  const request = {
    scopes: ['User.Read'],
    account: account,
  };

  try {
    const response = await msalInstance.acquireTokenSilent(request);
    const idToken = response?.idToken;
    // Decode the token to check the expiration time
    const decoded = jwtDecode(idToken);
    const currentTime = Math.floor(Date.now() / 1000);
    // If the token is expired or about to expire in less than 5 minutes, force a token refresh
    if (decoded.exp - currentTime <= 300) {
      throw new msal.InteractionRequiredAuthError();
    }

    return idToken;
  } catch (error) {
    if (error instanceof msal.InteractionRequiredAuthError) {
      try {
        const response = await msalInstance.acquireTokenSilent({
          ...request,
          forceRefresh: true,
        });
        return response?.idToken;
      } catch (silentError) {
        logger
          .error('Error acquiring token silently with force refresh')
          .data({ module: 'msalConfig', silentError })
          .end();
      }
    } else {
      logger
        .error('Error acquiring token silently')
        .data({ module: 'msalConfig', error })
        .end();
    }
  }
};
