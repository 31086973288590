import {
  Checkbox,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import React from 'react';
import { red } from '@mui/material/colors';
const EditSelectFindings = ({
  findingsList,
  selectedFindings,
  setSelectedFindings,
  inactivatedFindings,
  preSelectedFindings,
}) => {
  const handleSelectFinding = (findingId) => {
    if (selectedFindings.includes(findingId)) {
      setSelectedFindings(selectedFindings.filter((id) => id !== findingId));
    } else {
      setSelectedFindings([...selectedFindings, findingId]);
    }
  };

  const enableInactiveFindings = (findingId) => {
    const color =
      inactivatedFindings?.includes(findingId) ||
      (preSelectedFindings?.includes(findingId) &&
        !selectedFindings?.includes(findingId))
        ? red[500]
        : '';
    return color;
  };

  return (
    <>
      <List>
        {findingsList?.map((finding) => {
          const labelId = `checkbox-list-label-${finding.FindingId}`;

          return (
            <ListItem key={finding.FindingId} disablePadding>
              <ListItem dense>
                <ListItemIcon>
                  <Checkbox
                    sx={{
                      color: enableInactiveFindings(finding.FindingId),
                    }}
                    edge="start"
                    onChange={() => handleSelectFinding(finding?.FindingId)}
                    checked={selectedFindings?.includes(finding.FindingId)}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ 'aria-labelledby': labelId }}
                  />
                </ListItemIcon>
                <ListItemText
                  id={labelId}
                  primary={finding.FindingName}
                  secondary={`Details: ${finding.Details.map(
                    (d) => d.name
                  ).join(', ')}.`}
                />
              </ListItem>
            </ListItem>
          );
        })}
      </List>
    </>
  );
};

export default EditSelectFindings;
