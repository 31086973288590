import React from 'react';
import { NotificationManager } from 'react-notifications';
import { SubmitHiddenButton } from '../../Components';
import { FormContent } from './FormContent';
import TabForm from '../TabForm';
import styles from './AddOrEditImageLevelFinding.module.css';
import { useDispatch, useSelector } from 'react-redux';
import {
  getFindingsPreviousData,
  setFindingsPreviousData,
} from '../../../../redux/tasks/tasksSlice';
import { getUserName } from '../../../TasksList/TasksList.logic';

const AddOrEditImageLevelFinding = (props) => {
  const {
    tasksListLogic,
    findingsList,
    newFindingName,
    setNewFindingName,
    detailsExplanations,
    setDetailsExplanations,
    submitRef,
    findingId,
    setFindingId,
    oldFindingName,
    setOldFindingName,
    oldDetailsExplanations,
    setOldDetailsExplanations,
    isEdit,
    fetchData,
  } = props;

  //  Add

  //  Edit
  const dispatch = useDispatch();
  const isFindingExist = () => {
    return findingsList
      .map((item) => item.FindingName.toLowerCase())
      .includes(newFindingName.toLowerCase());
  };

  const isNewFindingName = () => {
    if (!isEdit) {
      return true;
    }
    return newFindingName.toLowerCase() !== oldFindingName.toLowerCase();
  };

  const selectFinding = (findingId) => {
    const finding = findingsList.find((item) => item.FindingId === findingId);

    if (finding) {
      const prevFindingDataObj = {
        findingId: finding?.FindingId.toString(),
        findingName: finding?.FindingName,
        details: finding?.Details,
      };
      dispatch(setFindingsPreviousData(prevFindingDataObj));

      setFindingId(finding.FindingId);
      setOldFindingName(finding.FindingName);
      setNewFindingName(finding.FindingName);
      setOldDetailsExplanations(finding.Details);
    } else {
      resetParameters();
    }
  };

  const handleSelectFinding = (finding) => {
    if (finding === null) {
      selectFinding('');
    } else {
      selectFinding(finding.FindingId);
    }
  };

  //  Common

  function findDuplicateDetails(details) {
    const otherFindings = findingsList.filter(
      (finding) => finding.FindingId !== findingId
    );

    const otherDetails = otherFindings.flatMap((finding) =>
      finding.Details.map((detail) => detail.name)
    );

    const duplicateDetails = details.filter((detail) => {
      const trimedLowerDetail = detail.trim().toLowerCase();
      return otherDetails.some(
        (otherDetail) => otherDetail.trim().toLowerCase() === trimedLowerDetail
      );
    });

    return duplicateDetails;
  }

  const resetParameters = () => {
    setDetailsExplanations(isEdit ? [] : [{ name: '', explanation: '' }]);
    setNewFindingName('');
    if (isEdit) {
      setFindingId('');
      setOldFindingName('');
      setOldDetailsExplanations([]);
    }
  };

  const addDetail = () => {
    setDetailsExplanations((x) => [...x, { name: '', explanation: '' }]);
  };

  const deleteDetail = (index) => {
    if (!isEdit && detailsExplanations.length === 1) {
      return;
    }
    setDetailsExplanations(detailsExplanations.filter((_, i) => i !== index));
  };

  const updateDetail = ({
    list: detailsExplanations,
    setList: setDetailsExplanations,
    index,
    detailNewName,
    detailNewExplanation,
  }) => {
    const updatedDetailsExplanations = [...detailsExplanations];
    updatedDetailsExplanations[index] = {
      name: detailNewName,
      explanation: detailNewExplanation,
      id: updatedDetailsExplanations[index].id,
    };
    setDetailsExplanations(updatedDetailsExplanations);
  };

  function findFirstDuplicateDetail(details) {
    const seen = new Set();

    for (let item of details) {
      if (seen.has(item)) {
        return item; // Return the first duplicate
      }
      seen.add(item);
    }

    return null; // No duplicates found
  }

  const previousFindingsData = useSelector(getFindingsPreviousData);
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (isNewFindingName() && isFindingExist()) {
      NotificationManager.error('Finding already exists.', 'Warning');
      return;
    }

    const details = isEdit
      ? oldDetailsExplanations.concat(detailsExplanations)
      : detailsExplanations;

    const detailsNames = details.map((d) => d.name);
    const duplicateDetail = findFirstDuplicateDetail(detailsNames);

    if (duplicateDetail) {
      NotificationManager.error(
        `Duplicated detail isn't allowed: '${duplicateDetail}'`,
        'Warning'
      );
      return;
    }

    const duplicates = findDuplicateDetails(detailsNames);
    if (duplicates.length > 0) {
      NotificationManager.error(
        `The details: [${duplicates}] already exists in other findings groups.`,
        'Warning'
      );
      return;
    }

    const newFinding = {
      findingId: findingId,
      findingName: newFindingName,
      details: details,
      isEdit,
    };

    try {
      const updatedBy = getUserName();
      const findingDetails = await tasksListLogic.postListOfFindings(
        newFinding
      );
      const activityLogs = {
        operation: isEdit ? 'EditFinding' : 'AddFinding',
        updatedBy,
        latestData: {
          findingId: findingDetails?.findingId.toString(),
          findingName: newFindingName,
          details: details,
        },
        previousData: isEdit ? previousFindingsData : {},
      };
      await tasksListLogic.addOrEditActivityLogs(activityLogs);
      await fetchData();
      resetParameters();
      NotificationManager.success(
        `Finding ${isEdit ? 'edited' : 'added'} successfully`,
        'Success!'
      );
    } catch (error) {
      console.error(`Error ${isEdit ? 'editing' : 'adding'} finding:`, error);
      NotificationManager.error(
        `An error occurred while ${
          isEdit ? 'editing' : 'adding'
        } the finding. Please try again later.`,
        'Warning'
      );
    }
  };

  const additionalProps = {
    ...props,
    handleSelectFinding,
    addDetail,
    deleteDetail,
    updateDetail,
  };

  return (
    <TabForm className={styles.tabForm} onSubmit={handleSubmit}>
      <FormContent {...additionalProps} />
      <SubmitHiddenButton type="submit" ref={submitRef} />
    </TabForm>
  );
};

export const AddImageLevelFinding = (props) => {
  return <AddOrEditImageLevelFinding {...props} />;
};

export const EditImageLevelFinding = (props) => {
  return <AddOrEditImageLevelFinding {...props} isEdit={true} />;
};
