import {
  Autocomplete,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import React, { useEffect } from 'react';
import {
  tier2NavigationModeOptions,
  ASSISTED_WORKS,
  MIN_LABELERS_NUMBER,
  MAX_LABELERS_NUMBER,
  USER_ROLES,
} from '../../SharedLogic';
import AutocompleteOrCreateNew from '../../../../../components/AutocompleteOrCreateNew';
import { RichTextEditor } from '../../../../../components';
import styles from './CampaignSettings.module.css';
import { LabeledSwitch } from '../../../Components';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectCampaignGuidance,
  selectConfig,
  selectUsersList,
  setCampaignsPreviousData,
} from '../../../../../redux/tasks/tasksSlice';

const EditCampaignSettings = ({
  campaignsList,
  campaignName,
  setCampaignName,
  campaignOwner,
  setCampaignOwner,
  setPreSelectedCampaignBatches,
  campaignGroup,
  setCampaignGroup,
  campaignsGroups,
  isHidden,
  setIsHidden,
  setSelectedBatches,
  numberOfTier1Labelers,
  setNumberOfTier1Labelers,
  tier2NavigationMode,
  setTier2NavigationMode,
  assistedWork,
  setAssistedWork,
  aiAsAdditionalTier1,
  setAiAsAdditionalTier1,
  disableAutoCorrection,
  setDisableAutoCorrection,
  campaignGuidance,
  handleChangeCampaignGuidance,
  setSelectedFindings,
  setPreSelectedUsersAndRoles,
  setSelectedUsersAndRoles,
  setInactivatedFindings,
  setPreSelectedFindings,
  fetchData,
}) => {
  const config = useSelector(selectConfig);
  const campaignGuidances = useSelector(selectCampaignGuidance);
  useEffect(() => {
    if (config && campaignName) {
      const selectedCampaignConfig = config.campaigns.find(
        (campaign) => campaign.campaign_name === campaignName
      );

      if (selectedCampaignConfig) {
        setNumberOfTier1Labelers(
          selectedCampaignConfig.params.number_of_tier1_labelers
        );
        setTier2NavigationMode(
          selectedCampaignConfig.params.tier_2_navigation_mode
        );
        setDisableAutoCorrection(
          selectedCampaignConfig.auto_correction_disable
        );
        setIsHidden(selectedCampaignConfig.hidden);
        setAssistedWork(selectedCampaignConfig.assistedWork);
      }
    }
  }, [
    config,
    campaignName,
    setNumberOfTier1Labelers,
    setTier2NavigationMode,
    setDisableAutoCorrection,
    setIsHidden,
    setAssistedWork,
  ]);
  const dispatch = useDispatch();
  const usersList = useSelector(selectUsersList);

  const handleCampaignChange = async (event, newValue) => {
    setCampaignName(newValue?.name);
    setCampaignGroup(newValue?.group);

    if (newValue?.id) {
      const selectedCampaignConfig = config.campaigns.find(
        (campaign) => campaign['campaign_id'] === newValue?.id
      );
      setCampaignOwner(selectedCampaignConfig['campaign_owner']);
      const guidance = campaignGuidances[newValue?.id];
      handleChangeCampaignGuidance(guidance);
      const campaignSelectedBatches = config.campaigns.find(
        (campaign) => campaign['campaign_id'] === newValue?.id
      ).params.batches;
      setSelectedBatches(campaignSelectedBatches);
      setPreSelectedCampaignBatches(campaignSelectedBatches);

      const preSelectedUsersAndRoles = config?.campaigns.find(
        (campaign) => campaign?.campaign_id === newValue?.id
      ).users;
      setPreSelectedUsersAndRoles(preSelectedUsersAndRoles);
      setSelectedUsersAndRoles(() =>
        usersList.map((user) => {
          return {
            ...user,
            roles: USER_ROLES.map((role) => ({
              label: role,
              checked: false,
            })),
          };
        })
      );

      const preSelectedFindings = config?.campaigns.find(
        (campaign) => campaign?.campaign_id === newValue?.id
      ).findings;
      const campaignInactivatedFindings =
        config?.campaigns.find(
          (campaign) => campaign?.campaign_id === newValue?.id
        ).inactivatedFindings || [];

      setSelectedFindings(preSelectedFindings);
      setPreSelectedFindings(preSelectedFindings);
      setInactivatedFindings([...campaignInactivatedFindings]);

      await fetchData(true, campaignSelectedBatches);

      const prevCampaignDataObj = {
        campaignId: campaignsList.find((x) => x.name === campaignName)?.id,
        campaignName: newValue?.name,
        campaignOwner: selectedCampaignConfig['campaign_owner'],
        campaignGroup: newValue?.group,
        usersAndRoles: preSelectedUsersAndRoles,
        batches: campaignSelectedBatches,
        hidden: isHidden,
        numberOfTier1Labelers,
        tier2NavigationMode,
        assistedWork,
        aiAsAdditionalTier1,
        disableAutoCorrection,
        findings: preSelectedFindings,
        campaignGuidance,
      };
      dispatch(setCampaignsPreviousData(prevCampaignDataObj));
    }
  };
  return (
    <>
      <Autocomplete
        options={campaignsList}
        getOptionLabel={(option) => option.name}
        value={
          campaignsList.find((campaign) => campaign.name === campaignName) ||
          null
        }
        onChange={handleCampaignChange}
        renderOption={(props, option) => (
          <li {...props} key={option.id}>
            {option.name}
          </li>
        )}
        renderInput={(params) => (
          <TextField {...params} label="Campaign" required variant="outlined" />
        )}
        selectOnFocus
        handleHomeEndKeys
      />
      {campaignName && (
        <>
          <TextField
            required
            label="Campaign Owner"
            value={campaignOwner}
            onChange={(e) => setCampaignOwner(e.target.value)}
          />
          <AutocompleteOrCreateNew
            selectedValue={campaignGroup}
            setSelectedValue={setCampaignGroup}
            values={campaignsGroups}
            fieldlabel="Campaign Group"
          />
          <TextField
            required
            label="Number of Tier1 Labelers"
            value={numberOfTier1Labelers}
            onChange={(e) => setNumberOfTier1Labelers(e.target.value)}
            type="number"
            inputProps={{
              min: MIN_LABELERS_NUMBER,
              max: MAX_LABELERS_NUMBER,
              step: 1,
            }}
            onKeyDown={(e) => e.preventDefault()}
            disabled={true}
          />
          <FormControl>
            <InputLabel id="select-tier2-navigation-mode-label">
              Tier2 Navigation Mode
            </InputLabel>
            <Select
              labelId="select-tier2-navigation-mode-label"
              required
              label={'Tier2 Navigation Mode'}
              value={tier2NavigationMode}
              onChange={(e) => setTier2NavigationMode(e.target.value)}
            >
              {tier2NavigationModeOptions?.map((option) => (
                <MenuItem required key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl>
            <InputLabel id="select-assisted-work-label">
              Assisted Work
            </InputLabel>
            <Select
              labelId="select-assisted-work-label"
              required
              label={'Assisted Work'}
              value={assistedWork}
              onChange={(e) => setAssistedWork(e.target.value)}
              disabled={true}
            >
              {ASSISTED_WORKS?.map((option) => (
                <MenuItem required key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <LabeledSwitch
            checked={disableAutoCorrection}
            setChecked={setDisableAutoCorrection}
            label={'Disable Auto Correction'}
          />
          <LabeledSwitch
            checked={aiAsAdditionalTier1}
            setChecked={setAiAsAdditionalTier1}
            label="AI as additional Tier1"
            disabled={true}
          />
          <LabeledSwitch
            checked={isHidden}
            setChecked={setIsHidden}
            label={'Hidden'}
          />
          <FormControl>
            <InputLabel shrink>Campaign Guidance</InputLabel>
            <RichTextEditor
              className={styles.richTextEditor}
              value={campaignGuidance}
              onChange={handleChangeCampaignGuidance}
              placeHolder="Campaign Guidance..."
            />
          </FormControl>
        </>
      )}
    </>
  );
};

export default EditCampaignSettings;
