import { useRef, useState } from 'react';
import {
  ASSISTED_WORKS,
  DEFAULT_LABELERS_NUMBER,
  tier2NavigationModeOptions,
} from '../Tabs/SharedLogic';
import { getUserName } from '../../TasksList/TasksList.logic';

const EditCampaignStates = ({
  tasksListLogic,
  campaignsList,
  findingsList,
  editBatchesList,
}) => {
  const [selectedUsersAndRoles, setSelectedUsersAndRoles] = useState([]);
  const [campaignName, setCampaignName] = useState('');
  const [campaignOwner, setCampaignOwner] = useState(getUserName());
  const [campaignGroup, setCampaignGroup] = useState('');
  const [campaignsGroups, setCampaignsGroups] = useState([]);
  const [selectedBatches, setSelectedBatches] = useState([]);
  const [preSelectedCampaignBatches, setPreSelectedCampaignBatches] = useState(
    []
  );
  const [inactivatedFindings, setInactivatedFindings] = useState([]);
  const [selectedFindings, setSelectedFindings] = useState([]);
  const [preSelectedFindings, setPreSelectedFindings] = useState([]);

  const [isHidden, setIsHidden] = useState(false);
  const [numberOfTier1Labelers, setNumberOfTier1Labelers] = useState(
    DEFAULT_LABELERS_NUMBER
  );
  const [tier2NavigationMode, setTier2NavigationMode] = useState(
    tier2NavigationModeOptions[0]
  );
  const [assistedWork, setAssistedWork] = useState(ASSISTED_WORKS[0]);
  const [aiAsAdditionalTier1, setAiAsAdditionalTier1] = useState(false);
  const [disableAutoCorrection, setDisableAutoCorrection] = useState(false);
  const [campaignGuidance, setCampaignGuidance] = useState('');
  const [activeStep, setActiveStep] = useState(0);
  const [usersSortOrder, setUsersSortOrder] = useState('asc');
  const [groupsSortOrder, setGroupsSortOrder] = useState('asc');
  const [sortBy, setSortBy] = useState('UserId');
  const [selectedFilteredGroups, setSelectedFilteredGroups] = useState([]);
  const [preSelectedUsersAndRoles, setPreSelectedUsersAndRoles] = useState({});
  const resetRef = useRef();

  const editCampaignProps = {
    tasksListLogic,
    campaignsList,
    findingsList,
    editBatchesList,
    preSelectedCampaignBatches,
    setPreSelectedCampaignBatches,
    selectedUsersAndRoles,
    setSelectedUsersAndRoles,
    campaignName,
    setCampaignName,
    campaignOwner,
    setCampaignOwner,
    campaignGroup,
    setCampaignGroup,
    campaignsGroups,
    setCampaignsGroups,
    selectedBatches,
    setSelectedBatches,
    selectedFindings,
    setSelectedFindings,
    inactivatedFindings,
    setInactivatedFindings,
    isHidden,
    setIsHidden,
    numberOfTier1Labelers,
    setNumberOfTier1Labelers,
    tier2NavigationMode,
    setTier2NavigationMode,
    assistedWork,
    setAssistedWork,
    aiAsAdditionalTier1,
    setAiAsAdditionalTier1,
    disableAutoCorrection,
    setDisableAutoCorrection,
    campaignGuidance,
    setCampaignGuidance,
    activeStep,
    setActiveStep,
    usersSortOrder,
    setUsersSortOrder,
    groupsSortOrder,
    setGroupsSortOrder,
    sortBy,
    setSortBy,
    selectedFilteredGroups,
    setSelectedFilteredGroups,
    preSelectedUsersAndRoles,
    setPreSelectedUsersAndRoles,
    preSelectedFindings,
    setPreSelectedFindings,
    resetRef,
  };

  const editCampaignButtons = [
    {
      label: 'Reset',
      onClick: () => resetRef.current.click(),
    },
  ];

  return { editCampaignProps, editCampaignButtons };
};

export default EditCampaignStates;
