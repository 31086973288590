import React, { useRef, useImperativeHandle, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { GroupedDropDownList } from '../../../../components';
import styles from './Labeling.module.css';
import useFindingsDetails from './FindingsDetails.js';
import { cloneDeep } from 'lodash';
import {
  NOT_SELECTED_FINDING_ID,
  NOT_SELECTED_STR,
} from '../../../../shared-logic/params.js';

const Labeling = (props) => {
  const {
    disabled,
    onChangedOutput,
    findings,
    details,
    detailsRef,
    findingsList,
    inactivatedFindingsList,
  } = props;
  const inputDetailsRef = useRef();
  const { findingsDetails } = useFindingsDetails();
  const findingsDisabledSelection = [NOT_SELECTED_STR];
  const [disableInactiveSelection, setDisableInactiveSelection] =
    useState(false);
  useImperativeHandle(detailsRef, () => ({
    focus: () => {
      inputDetailsRef.current.focus();
    },
  }));

  useEffect(() => {
    if (inactivatedFindingsList.includes(findings)) {
      setDisableInactiveSelection(true);
    } else {
      setDisableInactiveSelection(false);
    }
  }, [findings, inactivatedFindingsList]);

  const filterFindingsList = () => {
    const findingsListIds = cloneDeep(findingsList);
    const inactivatedFindingsListIds = cloneDeep(inactivatedFindingsList);

    findingsListIds.unshift(NOT_SELECTED_FINDING_ID);
    const filteredFindingsList = findingsDetails.filter((f) =>
      findingsListIds.includes(f.FindingId)
    );
    const filterInactivatedFindingsList = findingsDetails.filter((f) =>
      inactivatedFindingsListIds?.includes(f.FindingId)
    );
    findingsDisabledSelection.push(
      ...filterInactivatedFindingsList.map((f) => f.FindingName)
    );
    return [...filteredFindingsList, ...filterInactivatedFindingsList];
  };

  const findingsContent = filterFindingsList();

  const finding = findingsContent.find((f) => f.FindingId === findings);
  const findingValue = finding?.FindingName;
  const detail = finding?.Details.find((d) => d.id === details);
  const detailValue = detail?.name;

  return (
    <div className={styles.container}>
      <div className={styles.groupedDropDownListContainer}>
        <GroupedDropDownList
          id="findingsDropDownList"
          width={350}
          selectedValue={findingValue}
          label="Findings"
          items={[
            {
              label: 'Findings',
              content: findingsContent.map((f) => f.FindingName),
            },
          ]}
          onChange={(selection) => {
            const selectedFindings = findingsContent.find(
              (f) => f.FindingName === selection
            );
            onChangedOutput('findings', selectedFindings.FindingId);
          }}
          disabled={disabled || disableInactiveSelection}
          className={'findings'}
          disabledSelection={findingsDisabledSelection}
        />
      </div>
      <div className={styles.groupedDropDownListContainer}>
        <GroupedDropDownList
          innerRef={inputDetailsRef}
          id="detailsDropDownList"
          width={350}
          selectedValue={detailValue}
          label="Details"
          items={[
            {
              label: 'Details',
              content: finding.Details.map((d) => d.name),
            },
          ]}
          onChange={(selection) => {
            const detailId = finding.Details.find(
              (d) => d.name === selection
            ).id;
            onChangedOutput('details', detailId);
          }}
          disabled={disabled || disableInactiveSelection}
          className={'details'}
          disabledSelection={NOT_SELECTED_STR}
        />
      </div>
    </div>
  );
};

Labeling.propTypes = {
  disabled: PropTypes.bool,
  /**
   * Values of findings
   */
  findings: PropTypes.number,
  /**
   * Values of details
   */
  details: PropTypes.string,
  /**
   * Values of probability
   */

  probability: PropTypes.string,
  /**
   * Submit Event
   */
  onChangedOutput: PropTypes.func.isRequired,
  /**
   * a reference for details component
   */
  detailsRef: PropTypes.object,
  /**
   * a reference for findings component
   */
  findingsRef: PropTypes.object,

  /**
   * List of inactivatedFindings
   */

  inactivatedFindingsList: PropTypes.array,
};

Labeling.defaultProps = {
  disabled: false,
  findings: 'Oral Hygiene',
  details: 'Plaque',
  probability: '100%',
};

export default Labeling;
